import React, { useState } from "react";

import {
  Box,
  List,
  Divider,
  ListItem,
  Drawer,
  ListItemButton,
  ListItemText,
  AppBar,
} from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import ContactEmergencyRoundedIcon from "@mui/icons-material/ContactEmergencyRounded";
import BookmarkAddRoundedIcon from "@mui/icons-material/BookmarkAddRounded";
import ExploreRoundedIcon from "@mui/icons-material/ExploreRounded";
import DriveEtaRoundedIcon from "@mui/icons-material/DriveEtaRounded";
import CardTravelRoundedIcon from "@mui/icons-material/CardTravelRounded";
import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";
import Itinerary from "./Itinerary";
import ListBooking from "./ListBooking";
import Accommodation from "./Accommondation";

import User from "./User";
import Vendor from "./Vendor";
import { checkAdminRole, checkOper_or_admin } from "./utils/tool";
import Operating from "./Operating";
import AddBooking from "./AddBooking";
import MessTool from "./messtool";

function App() {
  const drawerWidth = 200;
  const styles = {
    box1: { flexGrow: 1, flex: 3 },

    appbar: {
      width: `calc(100% - ${drawerWidth}px)`,
      ml: `${drawerWidth}px`,
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: drawerWidth,
        boxSizing: "border-box",
      },
    },
  };

  const BOOKING = "BOOKING";
  const ADD_BOOKING = "ADD_BOOKING";
  const OPERATING = "OPERATING";
  const ITINERARY = "ITINERARY";
  const ACCOMMODATION = "ACCOMMODATION";
  const VENDOR = "VENDOR";
  const USER = "USER";
  const MESSTOOL = "MESSTOOL";

  const [drawer_data, set_drawer_data] = useState(BOOKING);

  const showing_drawer = (state) => {
    if (state === BOOKING) {
      return <ListBooking />;
    } else if (state === ADD_BOOKING) {
      return <AddBooking />;
    } else if (state === OPERATING) {
      return <Operating />;
    } else if (state === ITINERARY) {
      return <Itinerary />;
    } else if (state === ACCOMMODATION) {
      return <Accommodation />;
    } else if (state === VENDOR) {
      return <Vendor />;
    } else if (state === MESSTOOL) {
      return <MessTool />;
    } else if (state === USER) {
      return <User />;
    }
  };

  function ListItemCus({ data_show, icon, titleDATA }) {
    return (
      <ListItem
        disablePadding
        style={titleDATA == drawer_data ? { backgroundColor: "#E3F2FD" } : {}}
      >
        <ListItemButton onClick={() => set_drawer_data(titleDATA)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={data_show} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <AppBar position="fixed" sx={styles.appbar}></AppBar>
      <Drawer sx={styles.drawer} variant="permanent" anchor="left">
        <List>
          <ListItem>
            <ListItemButton>
              <img src="LOGO.jpg" height={100} />
            </ListItemButton>
          </ListItem>

          <ListItemCus
            titleDATA={BOOKING}
            data_show="List Booking"
            icon={<BookmarkAddRoundedIcon />}
          />
          <ListItemCus
            titleDATA={ADD_BOOKING}
            data_show="Sample Booking"
            icon={<BookmarkAddRoundedIcon />}
          />
          <ListItemCus
            titleDATA={ITINERARY}
            data_show="Itinerary"
            icon={<DriveEtaRoundedIcon />}
          />
          <ListItemCus
            titleDATA={ACCOMMODATION}
            data_show="Location"
            icon={<ExploreRoundedIcon />}
          />
          {checkAdminRole() ? (
            <ListItemCus
              titleDATA={VENDOR}
              data_show="Vendor"
              icon={<ContactEmergencyRoundedIcon />}
            />
          ) : null}
          {checkOper_or_admin() ? (
            <ListItemCus
              titleDATA={OPERATING}
              data_show="Operating"
              icon={<ContactEmergencyRoundedIcon />}
            />
          ) : null}
          {checkAdminRole() ? (
            <ListItemCus
              titleDATA={MESSTOOL}
              data_show="MessTool"
              icon={<MessageTwoToneIcon />}
            />
          ) : null}
          <ListItemCus
            titleDATA={USER}
            data_show="User"
            icon={<ManageAccountsRoundedIcon />}
          />
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={styles.box1}>
        {showing_drawer(drawer_data)}
      </Box>
    </div>
  );
}

export default App;
