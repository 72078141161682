import React, { useState, useEffect } from "react";
import {
  Box,
  LinearProgress,
  Button,
  Tabs,
  Tab,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  API_add_manually_operation,
  API_confirm_operating,
  API_create_operation,
  API_delete_operating,
  API_edit_operating,
  API_get_operating_booking_id,
  API_get_vendor,
  API_get_vendor_att,
  API_get_vendorgroup,
  API_regenerate_operation,
} from "../../utils/http";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import { AlertCus } from "../AlertCus";
import ListVendor from "./OperRight";
import { useTasks } from "../../AppContext";
import Editor from "../Editor/Editor";
import { checkOper_or_admin } from "../../utils/tool";
import ListVendorByDay from "./OperRightDay";
import BoxTotal from "./BoxTotal";
import {
  TOKEN_DOUBLE_RATE,
  TOKEN_SINGLE_RATE,
} from "../../utils/constDispatch";

export default function OperDetail({ booking_id }) {
  const [data, set_data] = useState(null);
  const [data_mail, set_data_mail] = useState(null);
  const [total_amount, set_total_amount] = useState(0);
  const [total_amount_customer, set_total_amount_customer] = useState(0);

  const [data_vendor, set_data_vendor] = useState(null);
  const [data_vendor_service, set_data_vendor_services] = useState(null);

  const [value, setValue] = React.useState(0);
  const [create_ok, set_create_ok] = useState(null);
  const [err_text, set_err_text] = useState("");
  const data_pdf = useTasks();

  const [value_adding, setValueAdding] = useState(null);
  const [value_adding_service, setValueAdding_service] = useState(null);

  const [day_adding, set_day_adding] = useState(1);

  const set_total = (x_data) => {
    let total_amount_tmp = 0;
    let total_amount_customer_tmp = 0;
    x_data.map((x_item) => {
      total_amount_tmp += x_item.price;
      total_amount_customer_tmp += x_item.price_for_customer;
    });

    set_total_amount(total_amount_tmp);
    set_total_amount_customer(total_amount_customer_tmp);
  };

  const getData = async () => {
    API_get_operating_booking_id(booking_id, (x) => {
      if (x.success) {
        set_data(x.data);
        set_data_mail(x.mail);
        set_total(x.data);
      }
    });
  };

  const create_operation = async () => {
    await API_create_operation(
      booking_id,
      (x) => {
        if (x.success) {
          set_create_ok(true);
          set_data(x.data);
          set_total(x.data);
        } else {
          set_create_ok(false);
          set_err_text(x.message);
        }
      },
      (err) => console.log(err)
    );
  };

  const regenerate_operation = () => {
    API_regenerate_operation(
      booking_id,
      (x) => {
        if (x.success) {
          set_create_ok(true);
          getData();
        } else {
          set_create_ok(false);
          set_err_text(x.message);
        }
      },
      (err) => console.log(err)
    );
  };

  async function ConfirmClick(booking_id, vendor_id) {
    await API_confirm_operating(
      { booking_id: booking_id, vendor_id: vendor_id },
      (x) => {
        if (x.success) {
          getData();
        }
      },
      (x) => console.log(x)
    );
  }

  async function DeleteClick(id) {
    await API_delete_operating(
      id,
      (x) => {
        if (x.success) {
          getData();
        }
      },
      (err) => console.log(err)
    );
  }
  async function EditOperClick(data) {
    let id = data._id;

    await API_edit_operating(
      id,
      data,
      (x) => {
        if (x.success) {
          getData();
        }
      },
      (err) => console.log(err)
    );
  }

  async function getDataVendor() {
    API_get_vendorgroup((data) => {
      set_data_vendor(data);
    });
  }

  async function get_vendor_att(vendor_id) {
    API_get_vendor_att(vendor_id, (data) => {
      console.log(data);
      set_data_vendor_services(data);
    });
  }

  async function addDataManually() {
    API_add_manually_operation(
      {
        booking_id: booking_id,
        service_id: value_adding_service._id,
        vendor_id: value_adding._id,
        day_index: parseInt(day_adding),
      },
      (x) => {
        if (x.success) {
          getData();
        }
      }
    );
  }

  useEffect(() => {
    getData();
    getDataVendor();
  }, []);

  if (data == null) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box>
        {data.length == 0 ? (
          <Button
            style={{ margin: 20 }}
            variant="outlined"
            startIcon={<AddCircleOutlineRoundedIcon />}
            onClick={() => create_operation()}
          >
            Create operation
          </Button>
        ) : (
          <Button
            style={{ margin: 20 }}
            variant="outlined"
            startIcon={<AutorenewRoundedIcon />}
            onClick={() => regenerate_operation()}
          >
            Regenerate
          </Button>
        )}
        <AlertCus condition={create_ok} error_text={err_text} />
        {checkOper_or_admin() ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 2,
            }}
          >
            <BoxTotal title="Total double before rate " price={total_amount} />
            <BoxTotal
              title="Total single before rate"
              price={total_amount_customer}
            />
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 2,
          }}
        >
          <BoxTotal
            title="Total double for sale"
            price={total_amount * sessionStorage.getItem(TOKEN_DOUBLE_RATE)}
          />
          <BoxTotal
            title="Total single for sale"
            price={
              total_amount_customer * sessionStorage.getItem(TOKEN_SINGLE_RATE)
            }
          />
        </Box>

        {checkOper_or_admin() ? (
          <Box>
            <Tabs
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label="By vendor" />
              <Tab label="By day" />
            </Tabs>
            {value == 0 ? (
              <ListVendor
                data_vendor={data_vendor}
                booking_details={data_pdf}
                vendor_details={data}
                data_mail={data_mail}
                ConfirmClick={ConfirmClick}
                DeleteClick={DeleteClick}
                EditOperClick={(data) => EditOperClick(data)}
              />
            ) : null}
            {value == 1 ? (
              <ListVendorByDay
                data_vendor={data_vendor}
                booking_details={data_pdf}
                vendor_details={data}
                ConfirmClick={ConfirmClick}
                DeleteClick={DeleteClick}
                EditOperClick={(data) => EditOperClick(data)}
              />
            ) : null}
          </Box>
        ) : null}
        {checkOper_or_admin() ? (
          <Box
            style={{
              flexDirection: "column",
              marginTop: 20,
              display: "flex",
              flexWarp: "wrap",
            }}
          >
            <Autocomplete
              isOptionEqualToValue={(option, value) => option._id === value._id}
              disablePortal
              disableClearable
              options={data_vendor}
              sx={{ width: 400 }}
              getOptionLabel={(option) => option.code}
              defaultValue={null}
              onChange={(e, value) => {
                setValueAdding(value);
                setValueAdding_service([]);
                get_vendor_att(value._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendor code"
                  value={params.code}
                />
              )}
            />
            {value_adding == null ? null : (
              <Autocomplete
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                disablePortal
                disableClearable
                options={data_vendor_service}
                sx={{ width: 400 }}
                getOptionLabel={(option) => option.code}
                defaultValue={null}
                onChange={(e, value) => setValueAdding_service(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="service code"
                    value={params.code}
                  />
                )}
              />
            )}

            {value_adding_service == null ? null : (
              <TextField
                id="day"
                label="day"
                value={day_adding}
                onChange={(e) => set_day_adding(e.target.value)}
                variant="outlined"
                type="number"
              />
            )}
            {day_adding >= 0 &&
            value_adding != null &&
            value_adding_service != null ? (
              <Button
                variant="outlined"
                disabled={value_adding == null || day_adding == 0}
                onClick={() => addDataManually()}
              >
                Add vendor manually
              </Button>
            ) : null}
          </Box>
        ) : null}
      </Box>
    );
  }
}
