export const STATUS_CODE = {
  proceeding: "proceeding",
  dealing: "dealing",
  failed: "failed",
  sample: "sample",
  done: "done",
};

export const ROLE_USER = {
  ADMIN: 100,
  OPERATOR: 70,
  EDITER: 50,
};

export const COLUMN_BOOKING_SAMPLE = [
  {
    headerName: "Booking REF",
    id: 0,
    filterable: false,
    minWidth: 300,
    field: "booking_number",
  },
  {
    headerName: "Tour",
    id: 4,
    filterable: false,
    minWidth: 300,
    field: "package",
  },
  {
    headerName: "Created date",
    minWidth: 200,
    id: 6,
    filterable: false,
    field: "created_at",
  },
  {
    headerName: "User create",
    minWidth: 200,
    id: 7,
    filterable: false,
    field: "created_by",
  },
];

export const COLUMN_BOOKING = [
  {
    headerName: "Status",
    id: 1,
    minWidth: 120,
    field: "status",
  },
  {
    headerName: "User create",
    id: 2,
    minWidth: 120,
    field: "created_by",
  },
  {
    headerName: "Booking REF",
    minWidth: 200,
    filterable: false,
    field: "booking_number",
  },
  {
    headerName: "Name",
    minWidth: 200,
    filterable: false,
    field: "customer_name",
  },
  {
    headerName: "Cell",
    id: 2,
    // width: 120,
    filterable: false,
    field: "customer_cell",
  },
  {
    headerName: "Email",
    id: 3,
    field: "customer_email",
    // flex: 1,
    filterable: false,
  },
  {
    // flex: 1,
    headerName: "Tour",
    id: 4,
    minWidth: 200,
    filterable: false,
    field: "package",
  },
  {
    headerName: "Travel date",
    id: 5,
    // width: 120,
    filterable: false,
    field: "day_start",
  },
  {
    headerName: "Created date",
    id: 6,
    // width: 120,
    filterable: false,
    field: "created_at",
  },
];

export const COLUMN_ACCOM = [
  {
    headerName: "code",
    id: 0,
    width: 200,
    filterable: false,
    field: "code",
  },
  {
    headerName: "city",
    id: 1,
    width: 120,
    filterable: false,
    field: "city",
  },
  {
    headerName: "hotel",
    id: 2,
    width: 300,
    filterable: false,
    field: "hotel",
  },
  {
    headerName: "type",
    id: 3,
    width: 120,
    field: "type",
    filterable: false,
  },
  // {
  //   headerName: "status",
  //   id: 4,
  //   width: 120,
  //   filterable: false,
  //   field: "status",
  // },
  {
    headerName: "remark",
    id: 5,
    width: 120,
    filterable: false,
    field: "remark",
  },
  {
    headerName: "address",
    id: 5,
    width: 300,
    filterable: false,
    field: "address",
  },
];
export const COLUMN_VENDOR = [
  {
    headerName: "vendor code",
    id: 0,
    width: 300,
    filterable: false,
    field: "code",
  },
  {
    headerName: "type",
    id: 0,
    width: 200,
    filterable: false,
    field: "type",
  },
];

export const COLUMN_SERVICE = [
  {
    headerName: "service code",
    id: 0,
    width: 200,
    filterable: false,
    field: "code",
  },
  {
    headerName: "double",
    id: 0,
    width: 100,
    filterable: false,
    field: "price",
  },
  {
    headerName: "single",
    id: 0,
    width: 100,
    filterable: false,
    field: "price_for_customer",
  },
  {
    headerName: "link",
    id: 0,
    width: 50,
    filterable: false,
    field: "link",
  },
  {
    headerName: "pickup",
    id: 0,
    width: 150,
    filterable: false,
    field: "pickup_place",
  },
  {
    headerName: "dropoff",
    id: 0,
    width: 150,
    filterable: false,
    field: "dropoff_place",
  },
];

export const COLUMN_OPER = [
  {
    headerName: "Booking REF",
    id: 0,
    width: 200,
    // filterable: false,
    field: "booking_ref",
  },
  {
    headerName: "Vendor code",
    id: 0,
    width: 200,
    filterable: false,
    field: "vendor_code",
  },
  {
    headerName: "Vendor type",
    id: 0,
    width: 150,
    filterable: false,
    field: "vendor_type",
  },
  {
    headerName: "Service code",
    id: 1,
    width: 200,
    filterable: false,
    field: "service_code",
  },
  // {
  //   headerName: "date",
  //   id: 1,
  //   width: 100,
  //   filterable: false,
  //   field: "day_index_detail",
  // },
  // {
  //   headerName: "day",
  //   id: 1,
  //   width: 100,
  //   filterable: false,
  //   field: "day_index",
  // },
  // {
  //   headerName: "Confirmed",
  //   id: 1,
  //   width: 100,
  //   filterable: false,
  //   field: "confirm",
  // },
];

export const COLUMN_USER = [
  {
    headerName: "username",
    id: 0,
    width: 400,
    filterable: false,
    field: "username",
  },
  {
    headerName: "role",
    id: 1,
    width: 300,
    filterable: false,
    field: "role",
  },
  {
    headerName: "created at",
    id: 2,
    width: 300,
    filterable: false,
    field: "date",
  },
];

export const COLUMN_MESSTOOL = [
  {
    headerName: "mess code",
    id: 0,
    // flex: 1,
    width: 250,
    filterable: false,
    field: "mess_code",
  },
  {
    headerName: "link",
    id: 0,
    width: 250,
    filterable: false,
    field: "link",
  },
];

export const COLUMN_ITINERRARY = [
  {
    headerName: "label",
    id: 0,
    flex: 1,
    width: 250,
    filterable: false,
    field: "label",
  },
  {
    headerName: "Location code",
    id: 2,
    width: 200,
    filterable: false,
    flex: 1,
    field: "accomodation_code",
  },
  {
    headerName: "activities",
    id: 1,
    width: 250,
    filterable: false,
    field: "activities",
  },
  {
    headerName: "created at",
    id: 1,
    width: 100,
    filterable: false,
    field: "created_at",
  },
  {
    headerName: "Pickup time",
    id: 3,
    width: 120,
    field: "pickuptime",
    filterable: false,
  },
  {
    headerName: "Transportation",
    id: 4,
    width: 100,
    filterable: false,
    field: "transportation",
  },
  {
    headerName: "Guide",
    id: 5,
    width: 100,
    filterable: false,
    field: "guide",
  },
];

export const COLUMN_ITINERRARY_MINI = [
  {
    headerName: "label",
    id: 0,
    width: 300,
    filterable: false,
    field: "label",
  },
];

export const COLUMN_ACCOM_MINI = [
  {
    headerName: "code",
    id: 0,
    width: 400,
    filterable: false,
    field: "code",
  },
];

export const COLUMN_VENDOR_SERVICE = [
  {
    headerName: "code",
    id: 0,
    width: 400,
    filterable: false,
    field: "code",
  },
  {
    headerName: "price",
    id: 1,
    width: 300,
    filterable: false,
    field: "price",
  },
];
export const TOKEN_NAME = "svntk";
export const TOKEN_ROLE_APP = "svntkroleapp";
export const TOKEN_USERNAME_APP = "svntkusernameapp";

export const TOKEN_RATE_AUD = "svntkrateaud";
export const TOKEN_RATE_USD = "svntkrateusd";

export const TOKEN_SINGLE_RATE = "svntksinglerate";
export const TOKEN_DOUBLE_RATE = "svntkdoublerate";

export const dispatchTYPE = {
  init: "init",
  login: "login",
  logout: "logout",
  get_data_pdf: "get_data_pdf",

  booking_number: "booking_number",
  prepared_by: "prepared_by",
  customer_name: "customer_name",
  customer_cell: "customer_cell",
  no_of_client: "no_of_client",
  check_in: "check_in",
  check_out: "check_out",
  package: "package",
  room_type: "room_type",
  tour_type: "tour_type",
  arrival_airport: "arrival_airport",
  arrival_flight: "arrival_flight",
  departure_airport: "departure_airport",
  departure_flight: "departure_flight",
  special_food_requirements: "special_food_requirements",
  tour_guide: "tour_guide",

  price_exclude: "price_exclude",
  price_include: "price_include",
  day_start: "day_start",

  customer_email: "customer_email",
  remember_to_bring: "remember_to_bring",
  note: "note",

  price_label: "price_label",
  price_price: "price_price",
  price_value: "price_value",

  activities_activities: "activities_activities",
  activities_accommondation: "activities_accommondation",
  activities_accommondation_address: "activities_accommondation_address",
  activities_accommondation_type: "activities_accommondation_type",
  activities_accommondation_location: "activities_accommondation_location",
  activities_accommondation_full: "activities_accommondation_full",
  activities_details: "activities_details",
  activities_pickuptime: "activities_pickuptime",
  activities_transportation: "activities_transportation",
  activities_guide: "activities_guide",

  activities_adding: "activities_adding",
  activities_deleting: "activities_deleting",

  accommondation_regenerate: "accommondation_regenerate",
  accommondation_adding: "accommondation_adding",
  accommondation_deleting: "accommondation_deleting",
  accommondation_hotel: "accommondation_hotel",
  accommondation_type: "accommondation_type",
  accommondation_status: "accommondation_status",
  accommondation_remark: "accommondation_remark",
  accommondation_address: "accommondation_address",

  draft_title_pdf: "draft_title_pdf",
  detail_title_pdf: "detail_title_pdf",
};
